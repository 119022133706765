<template>
  <div>
    <q-dialog
      v-model="isOpen"
      :maximized="isFullScreen"
    >
      <q-card
        v-if="user"
        class="relative"
        :class="appOptions.theme"
        style="width: 500px; max-width: 80vw;"
      >

        <q-card-section class="row">
          <div class="text-h6 text-center">
            {{ $t('My profile') }}
          </div>

          <q-space />

          <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            v-close-popup
          />
        </q-card-section>

        <q-card-section class="row text-center q-mx-xl">

          <q-input
            filled
            :label="$t('Name')"
            :model-value="user.username"
            color="light-teal"
            class="q-mb-sm col-12"
            @update:model-value="handleUpdate('username', $event)"
          />

          <q-input
            filled
            :model-value="user.email"
            color="light-teal"
            :label="$t('Email')"
            class="q-mb-sm col-12"
            @update:model-value="handleUpdate('email', $event)"
          />

          <q-input
            filled
            :model-value="user.password"
            :label="$t('Password')"
            color="light-teal"
            class="q-mb-sm col-12"
            @update:model-value="handleUpdate('password', $event)"
          />

<!--          <div-->
<!--            v-if="hasNotUserRole"-->
<!--            class="text-center q-mb-sm"-->
<!--          >-->
<!--            <strong>-->
<!--              {{ $t('Domains') + ': ' }}-->
<!--            </strong>-->

<!--            <span class="d-inline-block">{{ (user.domains || []).join(', ') || $t('none') }}</span>-->

<!--            <q-popup-edit-->
<!--              :model-value="user.domains"-->
<!--              :title="$t('Edit domains')"-->
<!--            >-->
<!--              {{ user.domains }}-->
<!--            </q-popup-edit>-->
<!--          </div>-->

          <q-input
            v-if="hasNotUserRole"
            filled
            :model-value="user.domain"
            :label="$t('Domain')"
            color="light-teal"
            class="q-mb-sm col-12"
            @update:model-value="handleUpdate('domain', $event)"
          />

          <q-input
            v-if="user.eav && hasOperatorRole"
            filled
            :model-value="user.eav['interactions-operator-number']"
            :label="$t('interactions-operator-number')"
            color="light-teal"
            class="q-mb-sm col-12"
            @update:model-value="handleEavUpdate('interactions-operator-number', $event)"
          />
        </q-card-section>

        <q-card-section class="row text-center q-mx-auto" style="display: flow;padding-top: 0!important;">
          <q-btn
            class="q-mr-sm q-my-sm"
            color="dark"
            text-color="white"
            :label="$t('Discard')"
            no-caps
            v-close-popup
          />

          <q-btn
            color="light-blue-9"
            class="q-my-sm"
            type="submit"
            no-caps
            :label="$t('Save')"
            @click="handleSave"
          />
        </q-card-section>

        <div class="row justify-center text-center q-px-md q-mb-sm" v-if="!this.simple">
          <div
            v-for="role in (user.roles || [])"
            :key="role.id"
            class="q-px-sm border-left border-right"
          >
            {{ role.name.length > 16 ? `${role.name.slice(0, 16)}...` : role.name }}
          </div>
        </div>

        <q-card-section :class="`${bg} q-pt-none`" v-if="!this.simple">
          <div class="row justify-center items-end q-pt-md">
            <q-btn
              class="q-mr-sm q-mb-xs"
              color="blue"
              :label="$t('Badge')"
              no-caps
              @click="printBadge"
            />

            <q-btn
              class="q-mb-xs"
              color="orange"
              :label="$t('History')"
              no-caps
              @click="handleHistory"
            />
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="isOpenHistory">
      <q-card
        v-if="user"
        class="q-pa-md"
        style="width: 700px; max-width: 80vw;"
      >
        <history
          :id="user.id"
          :entity="'Users\\Entity\\User'"
        />
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import History from './../history/history'

// Services
import { getBadge } from './../../services/requests/badge.service'
import { UserService } from '../../services'

export default {
  name: 'User',
  components: {
    History
  },
  data () {
    return {
      isOpen: false,
      isOpenHistory: false,
      simple: false
    }
  },
  mounted () {
    this.simple = this.appOptions.versionType === 'simple'
  },
  computed: {
    ...mapGetters([
      'user',
      'appOptions'
    ]),
    hasOperatorRole () {
      return this.user.roles.find(({ id }) => `${id}` === `31`)
    },
    hasNotUserRole () {
      return !this.user.roles.find(({ id }) => `${id}` === `2`)
    },
    bg () {
      return this.$q.dark.isActive
        ? ''
        : 'bg-blue-grey-1'
    },
    isFullScreen () {
      return this.isOpen && window.innerWidth < 700
    },
    badgeLink () {
      return `${this.appOptions.defaultServer}/users/badge/${this.user.id}`
    }
  },
  methods: {
    ...mapMutations([
      'updateUser',
      'setUser'
    ]),
    ...mapActions([
      'loadUserAccount',
      'saveUser'
    ]),
    handleLogout () {
      this.$channels.user.unsubscribe()
      this.$centrifuge.disconnect()

      UserService.logout()
      this.$eventBus.update('rules', { user: null })
    },
    // For now we have badge only in old interface
    // For this we need to authorized with our refresh token and after this to go to page with badge
    printBadge () {
      const user = JSON.parse(localStorage.getItem('user'))
      const win = window.open(`${this.appOptions.defaultServer}/users/login/terminal?token=${user.refresh_token}`, '_blank')
      win.focus()
      this.loadBadge(win)
    },
    // Check is page is redirected to home page
    // If it is on home page then go to badge
    // Max number of try is 10
    loadBadge (win, times = 10) {
      setTimeout(() => {
        if (win.document.URL !== this.appOptions.defaultServer && times > 0) {
          this.loadBadge(win, times - 1)
        }

        win.open(`${this.appOptions.defaultServer}/users/badge/${this.user.id}`, '_self')
      }, 1000)
    },
    updateLocalStorage (user) {
      localStorage.setItem('userData', JSON.stringify(user))
    },
    init () {
      this.loadUserAccount()
        .then(() => {
          if (!this.user.eav) {
            this.updateUser({ eav: {} })
          }

          this.updateLocalStorage(this.user)
        })
    },
    handleEavUpdate (key, value) {
      this.updateUser({
        eav: {
          ...(this.user.eav || {}),
          [key]: value
        }
      })
    },
    handleUpdate (key, value) {
      this.updateUser({ [key]: value })
    },
    openCloseProfile () {
      this.isOpen = !this.isOpen

      if (this.isOpen) {
        this.init()
      }
    },
    handleSave () {
      this.saveUser()
        .then(user => {
          this.updateLocalStorage(this.user)
          this.setUser(user)
        })
    },
    handleBadge () {
      getBadge(this.user.id)
        .then(console.debug)
    },
    handleHistory () {
      this.isOpenHistory = true
    }
  }
}
</script>
